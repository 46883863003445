import React from 'react';
import {
    ProcessContainer,
    ProcessTitle,
    ProcessSubTitle,
    ProcessWrapper,
    ProcessCard,
    ProcessCardIcon,
    ProcessCardTitle,
    ProcessCardSubTitle,
    ContactInformation,
    Process,
    ContactInfoWraper,
    ContactInfoItem,
    NavBtn,
    NavBtnLink
} from './PrcessElements';
import intro from '../../assets/images/Graphics/Introduction.svg';
import preLoi from '../../assets/images/Graphics/Pre-LOI.svg';
import Loi from '../../assets/images/Graphics/LOI.svg';
import Diligence from '../../assets/images/Graphics/Diligence.svg';
import logo from '../../assets/images/Logo.png';
import Close from '../../assets/images/Graphics/Close.svg';
import Phone from '../../assets/images/Graphics/Phone.svg';
import Email from '../../assets/images/Graphics/Email.svg';
import website from '../../assets/images/Graphics/website.svg';
import arrow from '../../assets/images/Graphics/Arrow.svg';
import arrow2 from '../../assets/images/Graphics/Arrow-2.svg';
import arrow3 from '../../assets/images/Graphics/Arrow-3.svg';
import arrow4 from '../../assets/images/Graphics/Arrow-4.svg';
import arrow5 from '../../assets/images/Graphics/Arrow-5.svg';
import pdf from '../../assets/Silvaner Capital Tearsheet.pdf';

const ProcessSection = () => {
    return (
        <>
            <ProcessContainer id='process'>
                <Process>
                    <ProcessTitle>Process</ProcessTitle>
                    <ProcessSubTitle>
                        Selling your business can be stressful. We provide a
                        clear and transparent process from start to finish.
                        The process typically takes 3-6 months, but we can move faster depending on the situation.
                    </ProcessSubTitle>
                    <ProcessWrapper>
                        <ProcessCard>
                            <ProcessCardIcon bgUrl={arrow}>
                                <img src={intro} alt="Introduction"/>
                            </ProcessCardIcon>
                            <ProcessCardTitle>
                                Introduction
                            </ProcessCardTitle>
                            <ProcessCardSubTitle>
                                Initial meeting and discussion of objectives
                            </ProcessCardSubTitle>
                        </ProcessCard>
                        <ProcessCard>
                            <ProcessCardIcon bgUrl={arrow2}>
                                <img src={preLoi} alt="Pre-LOI"/>
                            </ProcessCardIcon>
                            <ProcessCardTitle>
                                Pre-LOI
                            </ProcessCardTitle>
                            <ProcessCardSubTitle>
                                Exchange of high level business and financial information
                            </ProcessCardSubTitle>
                        </ProcessCard>
                        <ProcessCard>
                            <ProcessCardIcon bgUrl={arrow3}>
                                <img src={Loi} alt="LOI"/>
                            </ProcessCardIcon>
                            <ProcessCardTitle>
                                LOI
                            </ProcessCardTitle>
                            <ProcessCardSubTitle>
                                Letter of intent outlining major deal terms like purchase price and deal structure
                            </ProcessCardSubTitle>
                        </ProcessCard>
                        <ProcessCard>
                            <ProcessCardIcon bgUrl={arrow4}>
                                <img src={Diligence} alt="Diligence"/>
                            </ProcessCardIcon>
                            <ProcessCardTitle>
                                Diligence
                            </ProcessCardTitle>
                            <ProcessCardSubTitle>
                                Confirmatory diligence to verify historical financials and key business items
                            </ProcessCardSubTitle>
                        </ProcessCard>
                        <ProcessCard>
                            <ProcessCardIcon bgUrl={arrow5}>
                                <img src={Close} alt="Close"/>
                            </ProcessCardIcon>
                            <ProcessCardTitle>
                                Close
                            </ProcessCardTitle>
                            <ProcessCardSubTitle>
                                Signing of the purchase agreement and close of the transaction
                            </ProcessCardSubTitle>
                        </ProcessCard>
                    </ProcessWrapper>
                </Process>
        

                <ContactInformation>
                    <img src={logo} width={200}/>

                   <ContactInfoWraper>           
                     <ContactInfoItem>
                        <img src={Phone}/>

                        <span>
                            972-439-7143
                        </span>
                    </ContactInfoItem>

                    <ContactInfoItem>
                        <img src={Email}/>

                        <a href="mailto:johannes.hock@silvanercapital.com">
                            <span>
                                johannes.hock@silvanercapital.com
                            </span>
                        </a>
                    </ContactInfoItem>

                    <ContactInfoItem>
                        <img src={website}/>

                        <span>
                            www.silvanercapital.com
                        </span>
                    </ContactInfoItem>
                    </ContactInfoWraper> 
                  
                    
                       <NavBtn>
                        <NavBtnLink download href={pdf}>
                            Download <br/> Brochure
                        </NavBtnLink>
                    </NavBtn>
                </ContactInformation>
             </ProcessContainer>
        </>
    );
};

export default ProcessSection;
