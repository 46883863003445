import styled from 'styled-components';
import wave from '../../assets/images/Graphics/Bottom.svg';

export const AboutContainer = styled.div`
  min-height: 890px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  background-image: url(${wave});
  background-repeat: no-repeat;
  background-position: bottom;
`;

export const AboutTitle = styled.h1`
  font-size: 2.5rem;
  color: #0000C0;
  margin-bottom: 10px;
`;

export const AboutSubTitle = styled.p`
  font-size: 1.5rem;
  color: #000;
  margin-bottom: 10px;
  text-align: center;
  max-width: 850px; 
`;

export const Partner = styled.div`
  min-width:800px  
  margin: 50px;
  padding: 15px 30px;
  display: flex;
  background: #fafafa;
  border-radius: 60px;
  justify-content: flex-start;
  align-items: start;

  @media screen and (max-width: 960px) {
    flex-direction: column;
    justify-content: center;
    padding: 15px 20px;
  }
`;

export const PartnerImages = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 25px;
    
    img {
        width: 250px;
    }
    
    img:nth-child(1) {
        margin-bottom: 15px;
    }
`;

export const PartnerInfo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
`;

export const PartnerName = styled.p`
    font-weight: bold;
`;

export const PartnerPosition = styled.span`
    color: #0000C0
`;

export const PartnerBio = styled.span`
    font-size: 0.9rem;
    max-width: 500px;   
    margin-top: 20px;
`;
